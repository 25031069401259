import { useEffect, useState } from "react";

import {
  MdSearch,
  MdCloudUpload,
  MdSupervisorAccount,
  MdAccessAlarm,
  MdFeedback,
} from "react-icons/md";

import { encodedParams } from "../../utils/encodedParams";
import api from "../../services/api";

import { Container, Read } from "./styles";

interface UnreadProps {
  alert_unfinished: number;
  char_unread: number;
  info_unread: number;
}

export function MenuTabbar() {
  const [unread, setUnread] = useState<UnreadProps>({} as UnreadProps);

  async function loadUnCheckNumber() {
    try {
      const data = {
        ukey: "trial",
        fid: 2,
      };

      const params = encodedParams(data);
      const response = await api.post("/", params);

      setUnread(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadUnCheckNumber();
  }, []);

  useEffect(() => {
    const time = setInterval(async () => {
      loadUnCheckNumber();
    }, 1000 * 60 * 10); // 10min

    return () => clearInterval(time);
  }, []);

  return (
    <Container>
      <button>
        <MdSearch size={22} />
        <p>検索</p>
      </button>
      <button>
        <MdCloudUpload size={22} />
        <p>位置共有</p>
      </button>
      <button>
        {unread.char_unread > 0 && <Read>{unread.char_unread}</Read>}
        <MdSupervisorAccount size={22} />
        <p>チャット</p>
      </button>
      <button>
        {unread.alert_unfinished > 0 && <Read>{unread.alert_unfinished}</Read>}
        <MdAccessAlarm size={22} />
        <p>アラート</p>
      </button>
      <button>
        {unread.info_unread > 0 && <Read>{unread.info_unread}</Read>}
        <MdFeedback size={22} />
        <p>お知らせ</p>
      </button>
    </Container>
  );
}
