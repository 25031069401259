import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import {
  GoogleMap,
  LoadScript,
  KmlLayer,
  Marker,
  useGoogleMap,
} from "@react-google-maps/api";
import { MdLayers, MdMyLocation, MdLocationSearching } from "react-icons/md";
import { BsCursor } from "react-icons/bs";

import NewPinSvg from "../../asset/pin/new_pin.svg";
import MyLocalePin from "../../asset/pin/my-locale-pin.png";

import { encodedParams } from "../../../../utils/encodedParams";
import api from "../../../../services/api";

import { FilterDataDTO } from "../../../../dtos/FilterDataDTO";
import { ConfigDTO } from "../../../../dtos/ConfigDTO";
import { PinDTO } from "../../../../dtos/PinInfoDTO";

import {
  Container,
  ButtonContainer,
  Button,
  NavButton,
  PinImage,
  NewMarkerPin,
} from "./styles";

interface IGeolocation {
  latitude: number;
  longitude: number;
  heading: number;
}

interface GeoCode {
  formatted_address: string;
  location: {
    lat: number;
    lng: number;
  };
}

interface ICamera {
  center: {
    latitude: number;
    longitude: number;
  };
  heading: number;
  zoom: number;
}

interface MapKmlData {
  data: {
    kml: string;
  };
}

interface LatLng {
  latitude: number;
  longitude: number;
}

// interface IProps extends RectButtonProps {
//   closeModal: () => void;
//   lat?: string;
//   lng?: string;
//   filterData?: FilterDataDTO;
// }

interface IProps {
  searchAddress: GeoCode[] | null;
  openModal: (id: string) => void;
  setNewMapPin: (data: LatLng | null) => void;
  newMapPin: LatLng | null;
  modalStatus: boolean;
  onChange: boolean;
}
// {
//   closeModal,
//   modalStatus,
//   searchAddress,
//   setNewMapPin,
//   lat,
//   lng,
//   filterData,
//   ...rest
// }: IProps

export function GoogleMapContainer({
  searchAddress,
  openModal,
  setNewMapPin,
  newMapPin,
  modalStatus,
  onChange,
}: IProps) {
  const [geolocationPermission, setGeolocationPermission] = useState(false);
  const [mapRef, setMapRef] = useState<google.maps.Map | null>();

  const [isLoading, setIsLoading] = useState(true); // ロード画面用

  const [location, setLocation] = useState<IGeolocation>({
    // 現在地の取得（初期値東京駅）
    latitude: 35.6812362,
    longitude: 139.7649361,
    heading: 0,
  });
  const [camera, setCamera] = useState<ICamera>({
    // カメラ位置（初期値東京駅）
    center: {
      latitude: 35.6812362,
      longitude: 139.7649361,
    },
    heading: 0,
    zoom: 13,
  });

  const [mapType, setMapType] = useState<"roadmap" | "satellite">("roadmap"); // マップタイプ格納（初期設定ノーマル）
  const [centralizeCameraOnUser, setCentralizeCameraOnUser] = useState(false); // カメラ中央常時設定（初期設定オフ）
  const [mapKml, setMapKml] = useState<string>(); // KMLファイル
  // const [pinList, setPinList] = useState<PinDTO[]>([] as PinDTO[]);

  const [recordSetting, setRecordSetting] = useState(180);

  console.log(mapKml);

  const loadPin = useCallback(async () => {
    try {
      console.log("loadpin");

      // ピン情報取得
      const data = {
        ukey: "trial",
        fid: 4,
        lat: location.latitude,
        lng: location.longitude,
        is_cslst: 1,
      };
      const params = encodedParams(data);
      const response = await api.post("/", params);

      const kmlFile = response.data.data.kml + "?rf=" + Date.now().toString();
      setMapKml(kmlFile);
    } catch (error) {
      console.log(error);
    }
  }, [location]);

  function getReady(e: google.maps.Map) {
    setMapRef(e);

    if (navigator.geolocation) {
      setGeolocationPermission(true);

      navigator.geolocation.getCurrentPosition(
        // 許可処理
        (position) => {
          // 現在地の取得
          e.panTo(
            new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            )
          );
        },
        // 未許可処理
        (error) => {
          if (error.PERMISSION_DENIED) {
            setGeolocationPermission(false);

            console.log("code:", error.code, "msg:", error.message);
            return alert("位置情報へのアクセス権が拒否されました");
          }

          if (error.POSITION_UNAVAILABLE) {
            console.log("code:", error.code, "msg:", error.message);
            return alert("位置情報が取得できませんでした。");
          }

          console.log("code:", error.code, "msg:", error.message);
          return alert("位置情報取得でエラーが発生しました");
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
        }
      );
    } else {
      setGeolocationPermission(false);
      alert("位置情報の取得ができません");
    }
  }

  // 住所検索時、ピンにフォーカス
  useEffect(() => {
    if (searchAddress != null) {
      mapRef?.panTo({
        lat: searchAddress[0].location.lat,
        lng: searchAddress[0].location.lng,
      });
    }
  }, [searchAddress]);

  // 初期読み込み
  useEffect(() => {
    (async () => {
      if (navigator.geolocation) {
        setGeolocationPermission(true);

        navigator.geolocation.getCurrentPosition(
          // 許可処理
          (position) => {
            // 現在地の取得
            const parseLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              heading: position.coords.heading || 0,
            };

            setLocation(parseLocation);

            // カメラ情報の設定
            setCamera({
              center: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              heading: position.coords.heading || 0,
              zoom: 13,
            });

            loadPin();
          },
          // 未許可処理
          (error) => {
            if (error.PERMISSION_DENIED) {
              setGeolocationPermission(false);

              console.log("code:", error.code, "msg:", error.message);
              return alert("位置情報へのアクセス権が拒否されました");
            }

            if (error.POSITION_UNAVAILABLE) {
              console.log("code:", error.code, "msg:", error.message);
              return alert("位置情報が取得できませんでした。");
            }

            console.log("code:", error.code, "msg:", error.message);
            return alert("位置情報取得でエラーが発生しました");
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
          }
        );

        // 設定データ取得
        const settingData = {
          ukey: "trial",
          fid: 1,
          type: 2,
        };
        const settingDataParams = encodedParams(settingData);
        const settingResponse = await api.post("/", settingDataParams);
        const recordSettingResponse = settingResponse.data.data.setting.find(
          // 記録間隔の取得
          (d: ConfigDTO) => d.title === "記録時間間隔"
        );

        const parseRecordSetting = Math.round(recordSettingResponse.value / 60); // 秒数に変換
        setRecordSetting(parseRecordSetting || 180);
      } else {
        setGeolocationPermission(false);
        alert("位置情報の取得ができません");
      }

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    loadPin();
  }, [onChange]);

  // // 絞り込み検索
  // useEffect(() => {
  //   (async () => {
  //     setIsLoading(true);

  //     // ピン情報取得
  //     const data = {
  //       ukey: "trial",
  //       fid: 4,
  //       lat: location.latitude,
  //       lng: location.longitude,
  //       is_cslst: 1,
  //       si: filterData,
  //     };
  //     const params = encodedParams(data);
  //     const response = await api.post("/", params);

  //     setPinList(response.data.data.cslst);

  //     setIsLoading(false);
  //   })();
  // }, [filterData]);

  // 現在地の常時取得
  useEffect(() => {
    if (geolocationPermission) {
      const time = setInterval(async () => {
        navigator.geolocation.watchPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              heading: position.coords.heading || 0,
            });
          },
          (error) => {
            if (error.PERMISSION_DENIED) {
              setGeolocationPermission(false);

              console.log("code:", error.code, "msg:", error.message);
              return alert("位置情報へのアクセス権が拒否されました");
            }

            if (error.POSITION_UNAVAILABLE) {
              console.log("code:", error.code, "msg:", error.message);
              return alert("位置情報が取得できませんでした。");
            }

            console.log("code:", error.code, "msg:", error.message);
            return alert("位置情報取得でエラーが発生しました");
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      }, 2000);

      return () => clearInterval(time);
    }
  }, [centralizeCameraOnUser, geolocationPermission]);

  // マップタイプ変更
  function handleChangeMapType() {
    if (mapType === "roadmap") {
      return setMapType("satellite");
    }

    setMapType("roadmap");
  }

  // カメラを現在地に移動
  async function handleSetCameraToCenter() {
    const currentCamera = mapRef?.getCenter();

    if (
      currentCamera &&
      currentCamera.lat() === camera.center.latitude &&
      currentCamera.lng() === camera.center.longitude
    ) {
      const zoom = mapRef?.getZoom();
      if (zoom) {
        mapRef?.setZoom(zoom + 1);
      }

      return mapRef?.panTo(
        new google.maps.LatLng(location.latitude, location.longitude)
      );
    }

    mapRef?.panTo(
      new google.maps.LatLng(location.latitude, location.longitude)
    );
  }

  // カメラ常時中央に設定
  function handleCentralizeCamera() {
    mapRef?.panTo({
      lat: location.latitude,
      lng: location.longitude,
    });

    setCentralizeCameraOnUser((oldState) => !oldState);
  }

  useEffect(() => {
    if (centralizeCameraOnUser) {
      const time = setInterval(() => {
        const currentCamera = mapRef?.getCenter();

        if (
          currentCamera?.lat() !== location.latitude ||
          currentCamera?.lng() !== location.longitude
        ) {
          mapRef?.panTo({
            lat: location.latitude,
            lng: location.longitude,
          });
        }
      }, 1500);

      return () => clearInterval(time);
    }
  }, [centralizeCameraOnUser, location, mapRef]);

  // 新規登録ピン
  async function handleSetNewPin(e: google.maps.MapMouseEvent) {
    if (!e.latLng) {
      return;
    }
    const latLng = {
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    };
    setNewMapPin(latLng);
    openModal("newpin");

    if (mapRef && !centralizeCameraOnUser) {
      return mapRef?.panTo(
        new google.maps.LatLng(e.latLng.lat(), e.latLng.lng())
      );
    }
  }

  // // ピンタップした場合
  // function handleTapPin(e: MapEvent) {
  //   if (e.nativeEvent.id !== "newpin") {
  //     setNewMapPin(null);
  //     return openModal(e.nativeEvent.id!);
  //   }
  //   setNewMapPin(null);
  //   return closeModal();
  // }

  // // lat, lngがあった場合カメラ移動
  // useEffect(() => {
  //   if (lat && lng) {
  //     (async () => {
  //       if (mapRef.current !== null) {
  //         const currentCamera = await mapRef.current.getCamera();

  //         if (
  //           currentCamera.center.latitude !== lat ||
  //           currentCamera.center.longitude !== lng
  //         ) {
  //           mapRef.current.animateCamera({
  //             center: {
  //               latitude: lat,
  //               longitude: lng,
  //             },
  //           });
  //         }
  //       }
  //     })();
  //   }
  // }, [lat, lng]);

  /* 現在位置の記録                       */
  // useEffect(() => {
  //   const time = setInterval(async () => {
  //     const data = {
  //       ukey: "trial",
  //       fid: 6,
  //       lat: location.latitude,
  //       lng: location.longitude,
  //     };
  //     const params = encodedParams(data);

  //     await api.post("/", params);
  //   }, recordSetting * 1000); // 1000ms * recordSetting(秒)

  //   return () => clearInterval(time);
  // }, []);
  /* ---------------------------------- */

  // マップスタイルオプション
  const mapContainerStyle = useMemo(() => {
    let height = "100%";
    if (modalStatus) {
      height = "45%";
    }

    return {
      width: "100%",
      height,
      marginBottom: "auto",
    };
  }, [modalStatus]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBZ1IbqT-yYvN8XiU9sF6w5mr3lfu5rQi0"
      language="ja"
    >
      <GoogleMap
        id=""
        mapTypeId={mapType}
        zoom={17}
        // center={{
        //   lat: 35.69575,
        //   lng: 139.77521,
        // }}
        mapContainerStyle={mapContainerStyle}
        onLoad={(e: google.maps.Map) => getReady(e)}
        options={{
          mapTypeControl: false,
          panControl: false,
          scaleControl: false,
          zoomControl: false,
          fullscreenControl: false,

          draggable: !centralizeCameraOnUser,
          scrollwheel: !centralizeCameraOnUser,
        }}
        onClick={(e) => handleSetNewPin(e)}
      >
        <KmlLayer
          options={{
            preserveViewport: true,
          }}
          url={mapKml}
          // onClick={(e) => handleSetNewPin(e)}
        />

        {!!newMapPin && ( // 新規ピン
          <Marker
            key="newpin"
            title="新規登録ピン"
            position={{
              lat: newMapPin.latitude,
              lng: newMapPin.longitude,
            }}
            icon={{
              url: NewPinSvg,
              // scaledSize: new google.maps.Size(40, 40),
            }}
          />
        )}

        <Marker // 現在地ピン
          title="mylocale"
          position={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          icon={{
            url: MyLocalePin,
            // scaledSize: new google.maps.Size(40, 40)
          }}
        />

        {!!searchAddress &&
          searchAddress.map((pin) => (
            <Marker // 住所検索ピン
              key={pin.formatted_address}
              title={pin.formatted_address}
              position={{
                lat: pin.location.lat,
                lng: pin.location.lng,
              }}
              icon={{
                url: NewPinSvg,
                // scaledSize: new google.maps.Size(40, 40),
              }}
            />
          ))}

        {!modalStatus && (
          <ButtonContainer>
            <Button onClick={handleChangeMapType}>
              <MdLayers name="layers" size={24} />
            </Button>
            <Button onClick={handleCentralizeCamera}>
              {centralizeCameraOnUser ? (
                <MdMyLocation name="my-location" size={24} />
              ) : (
                <MdLocationSearching name="location-searching" size={24} />
              )}
            </Button>

            <NavButton onClick={handleSetCameraToCenter}>
              <BsCursor size={18} />
            </NavButton>
          </ButtonContainer>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

// add-location-alt
// function getRandomInRange(from: number, to: number) {
//   return Math.random() * (to - from) + from;
//   // .toFixed() returns string, so ' * 1' is a trick to convert to number
// }

// const mark = Array.from({ length: 1000 }, () => {
//   return {
//     latitude: getRandomInRange(34.9, 35),
//     longitude: getRandomInRange(136.9, 137),
//   };
// });

{
  /* <MapView
        provider={PROVIDER_GOOGLE}
        style={{
          flex: 1,
          width: Dimensions.get("window").width,
          height: Dimensions.get("window").height,
        }}
        initialRegion={{
          latitude: location.latitude,
          longitude: location.longitude,
          latitudeDelta: 0.006,
          longitudeDelta: 0.006,
        }}
        ref={mapRef}
        showsUserLocation
        mapType={mapType}
        showsMyLocationButton={false}
        onPress={(e) => handleSetNewPin(e)}
        scrollEnabled={centralizeCameraOnUser === false}
        showsIndoorLevelPicker={true}
        onMarkerPress={(e) => handleTapPin(e)}
      >
       

        {pinList.map((pin) => (
          <Marker
            identifier={`${String(pin.mpid).padStart(7, "0")}${String(
              pin.csid
            ).padStart(3, "0")}`}
            key={`${pin.lat}${pin.lng}`}
            title={pin.lbnm}
            coordinate={{
              latitude: Number(pin.lat),
              longitude: Number(pin.lng),
            }}
            onPress={(e: MapEvent) => {
              e.stopPropagation();
            }}
          >
            <PinImage source={{ uri: pin.pim }} resizeMode="contain" />
          </Marker>
        ))}

        
      </MapView> */
}

{
  /* {!modalStatus && (
            <ButtonContainer>
              <Button onPress={handleChangeMapType} {...rest}>
                <MaterialIcons name="layers" size={24} />
              </Button>
              <Button {...rest} onPress={handleCentralizeCamera}>
                {centralizeCameraOnUser ? (
                  <MaterialIcons name="my-location" size={24} />
                ) : (
                  <MaterialIcons name="location-searching" size={24} />
                )}
              </Button>

              <NavButton onPress={handleSetCameraToCenter} {...rest}>
                <SimpleLineIcons
                  name="cursor"
                  size={18}
                  color={theme.colors.background_primary}
                />
              </NavButton>
            </ButtonContainer>
          )} */
}
