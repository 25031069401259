import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import "react-datepicker/dist/react-datepicker.css";

import GlobalStyle from "./styles/Global";

function App() {
  return (
    <Router>
      <GlobalStyle />

      <Routes />
    </Router>
  );
}

export default App;
