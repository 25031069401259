import styled from "styled-components";
import { colors } from "../../../../../../styles/theme";

interface ButtonProps {
  isSelected: boolean;
}

interface ContainerProps {
  isClose: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: ${({ isClose }) => (isClose ? "16px" : "10px")};
`;

export const ButtonWrapper = styled.button<ButtonProps>`
  width: 40px;
  height: 40px;

  border-radius: 20px;
  box-shadow: 0 3px 5px ${colors.shadow_200};

  display: flex;

  justify-content: center;
  align-items: center;

  border: 1px solid ${colors.border_100};

  background-color: ${({ isSelected }) =>
    isSelected ? colors.main : colors.background_primary};

  svg {
    color: ${({ isSelected }) =>
      isSelected ? colors.background_primary : colors.text_detail};
  }
`;

export const Title = styled.p`
  color: ${colors.text_detail};

  font-size: 8px;
  margin-top: 5px;
`;
