import { Routes, Route } from "react-router-dom";
import { MenuTabbar } from "../components/MenuTabbar";
import { Home } from "../pages/Home";

import { Container } from "./styles";

export default function Router() {
  return (
    <Container>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/event" element={<Event />} />
          <Route path="/daily-report" element={<DailyReport />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
      <MenuTabbar />
    </Container>
  );
}
