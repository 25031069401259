import styled, { css, keyframes } from "styled-components";
import { colors } from "../../../../styles/theme";

export const RankingContainer = styled.div`
  width: 100%;
  height: 35px;

  background-color: ${colors.background_secondary};

  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;

  border-radius: 5px;

  overflow: hidden;
  white-space: nowrap;
`;

export const OneIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const loop = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

interface Props {
  isStop: boolean;
}

export const RankingContentWrapper = styled.button<Props>`
  display: flex;
  flex-wrap: nowrap;

  ${({ isStop }) =>
    !isStop &&
    css`
      -webkit-animation: ${loop} 36s infinite linear 1s both;
      animation: ${loop} 36s infinite linear 1s both;
    `}

  padding: 0 10px;

  outline: none;
  border: none;

  background-color: ${colors.background_secondary};
`;

export const Ranking = styled.div`
  display: flex;

  align-items: center;

  padding-right: 30px;

  h3 {
    color: ${colors.text};

    font-size: 12px;
    padding-right: 30px;
  }

  p {
    color: ${colors.text_detail};

    font-size: 12px;
    margin-left: 4px;
  }
`;

export const RankingContent = styled.div`
  display: flex;

  align-items: center;
  margin-right: 10px;

  strong {
    color: ${colors.text_detail};

    font-size: 12px;
  }

  p {
    color: ${colors.text_detail};

    font-size: 12px;
    margin-left: 4px;
  }
`;
