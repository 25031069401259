import { forwardRef } from "react";
import { IoCaretDownSharp } from "react-icons/io5";

import { Container, StyledInput } from "./styles";

interface InputProps {
  className: string;
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
}

function Input({ className, value, onClick, onChange }: InputProps, ref: any) {
  return (
    <Container>
      <StyledInput
        className={className}
        type="text"
        value={value}
        ref={ref}
        onChange={(e) => onChange(e.target.value)}
        onClick={onClick}
        placeholder="指定なし"
      />
      <IoCaretDownSharp size={12} />
    </Container>
  );
}

export default forwardRef(Input);
