import React, { FormEvent, useEffect, useRef, useState } from "react";

import { MdClose, MdSearch } from "react-icons/md";
import { IoCaretForwardSharp, IoCaretBackSharp } from "react-icons/io5";

import { FilterDataDTO } from "../../dtos/FilterDataDTO";

import {
  Container,
  Header,
  CreateNewContainer,
  SearchBoxContainer,
  ShowSearchBox,
  SearchBox,
  SearchBoxInput,
  ClearIconButton,
} from "./styles";

import { geocode } from "../../utils/geocode";
import { RankingHeader } from "./components/RankingHeader";
import { GoogleMapContainer } from "./components/GoogleMapContainer";
import { CreateNewPin } from "./Modals/CreateNewPin";
import { MenuTabbar } from "../../components/MenuTabbar";

interface Params {
  mpid: string;
  csid: string;
  lat: string;
  lng: string;
  filterData: FilterDataDTO;
}

interface GeoCode {
  formatted_address: string;
  location: {
    lat: number;
    lng: number;
  };
}

interface LatLng {
  latitude: number;
  longitude: number;
}

export function Home() {
  const formRef = useRef<HTMLFormElement>(null);
  const [onChange, setOnChange] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [openAll, setOpenAll] = useState(false);

  const [newMapPin, setNewMapPin] = useState<LatLng | null>(null); // Mappin
  const [pinId, setPinId] = useState<string>(""); // Mappin

  const [searchBoxHidden, setSearchBoxHidden] = useState(true); // 検索ボックス開閉ステータス
  const [searchBoxText, setSearchBoxText] = useState(""); // 検索ボックスInput
  function handleOpenCloseSearchBox() {
    // 検索ボックス開閉
    setSearchBoxHidden(!searchBoxHidden);
  }
  const [searchAddress, setSearchAddress] = useState<GeoCode[] | null>(null); // 検索住所

  async function handleSearchAddress(event: FormEvent) {
    event?.preventDefault();

    if (!searchBoxText) {
      return;
    }
    const response = await geocode(searchBoxText);

    const parseGeo = response.results.map(
      (geo: {
        formatted_address: any;
        geometry: { location: { lat: any; lng: any } };
      }) => {
        const data = {
          formatted_address: geo.formatted_address,
          location: {
            lat: geo.geometry.location.lat,
            lng: geo.geometry.location.lng,
          },
        };

        return data;
      }
    );

    setSearchAddress(parseGeo);
  }

  function handleCloseModal() {
    // モーダル閉じる
    setNewMapPin(null);
    setModalOpen(false);
    setOnChange(!onChange);
    setOpenAll(false);
  }

  function handleOpenModal(id: string) {
    console.log("openModal", id);
    // モーダル開ける
    setPinId(id);
    setModalOpen(true);
  }

  function handleSetOpenAll() {
    // 新規登録画面大きさ切替
    setOpenAll(!openAll);
  }

  function handleRemoveSearchAddress() {
    // 検索ボックス・検索住所　消去
    setSearchBoxText("");
    setSearchAddress(null);
  }

  // RouteからParams受け取った場合
  // useEffect(() => {
  //   if (routeParams && routeParams.mpid && routeParams.csid) {
  //     const id =
  //       routeParams.mpid.padStart(7, "0") + routeParams.csid.padStart(3, "0");
  //     handleOpenModal(id);
  //   }
  // }, [routeParams]);

  return (
    <Container open={modalOpen}>
      {!openAll && (
        <Header>
          <RankingHeader />
        </Header>
      )}
      <SearchBoxContainer open={openAll}>
        <ShowSearchBox onClick={handleOpenCloseSearchBox}>
          {searchBoxHidden ? (
            <IoCaretForwardSharp color="#FFFFFF" />
          ) : (
            <IoCaretBackSharp color="#FFFFFF" />
          )}
        </ShowSearchBox>

        {!searchBoxHidden && (
          <SearchBox action="#" onSubmit={handleSearchAddress} ref={formRef}>
            <MdSearch size={22} />
            <SearchBoxInput
              onChange={(searchString) => {
                setSearchBoxText(searchString.target.value);
              }}
              name="searchInput"
              value={searchBoxText}
              placeholder="ここで検索"
              type="search"
            />
            <ClearIconButton onClick={handleRemoveSearchAddress}>
              <MdClose size={22} />
            </ClearIconButton>
          </SearchBox>
        )}
      </SearchBoxContainer>
      <GoogleMapContainer
        searchAddress={searchAddress}
        openModal={handleOpenModal}
        setNewMapPin={setNewMapPin}
        newMapPin={newMapPin}
        modalStatus={modalOpen}
        onChange={onChange}
      />

      {/* <GoogleMap
          closeModal={handleCloseModal}
          setNewMapPin={setNewMapPin}
          lat={routeParams && routeParams.lat}
          lng={routeParams && routeParams.lng}
          filterData={routeParams && routeParams.filterData}
        /> */}

      {modalOpen && (
        <CreateNewContainer open={openAll}>
          <CreateNewPin
            pin_id={pinId}
            latitude={newMapPin?.latitude}
            longitude={newMapPin?.longitude}
            closeModal={handleCloseModal}
            openAll={openAll}
            setOpenAll={handleSetOpenAll}
          />
        </CreateNewContainer>
      )}
      <MenuTabbar />
    </Container>
  );
}

// var formdata = new FormData($('#upload_file').get(0));
// formdata.append('ukey', g_ukey);
