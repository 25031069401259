import styled, { css } from "styled-components";
import { colors } from "../../styles/theme";

// import { MaterialIcons } from "@expo/vector-icons";

interface ModalProps {
  open: boolean;
}

export const Container = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  flex: 1;

  margin-bottom: ${({ open }) => (open ? 0 : "80px")};

  position: relative;
`;

export const Header = styled.div`
  width: 100%;
  height: 90px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 9px;

  background-color: ${colors.background_primary};
`;

export const CreateNewContainer = styled.div<ModalProps>`
  z-index: 3;
  width: 100%;

  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  height: ${({ open }) => (open ? "100%" : "45%")};

  background: red;
`;

export const SearchBoxContainer = styled.div<ModalProps>`
  position: absolute;
  z-index: ${({ open }) => (open ? 0 : 1)};
  top: 90px;

  width: 100%;
  height: 45px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  padding-right: 10px;
`;

export const ShowSearchBox = styled.button`
  width: 16px;
  height: 35px;
  background-color: ${colors.text_detail};

  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;
`;

export const SearchBox = styled.form`
  flex: 1;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.background_primary};
  border-radius: 45px;

  box-shadow: 1px 1px 3px ${colors.shadow_100};

  border: none;
  outline: none;

  padding: 9px 0 9px 15px;

  svg {
    color: #cccccc;
  }
`;

export const SearchBoxInput = styled.input`
  flex: 1;
  font-size: 12px;
  border-radius: 45px;

  border: none;
  outline: none;

  color: ${colors.text_detail};

  padding: 9px 15px;

  background-color: ${colors.background_primary};

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const ClearIconButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  padding: 9px 15px 9px 0;

  svg {
    color: #cccccc;
  }
`;
