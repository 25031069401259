import Select, {
  components,
  PlaceholderProps,
  DropdownIndicatorProps,
} from "react-select";
import { IoCaretDownSharp } from "react-icons/io5";

import { Section, SectionTitle, SelectSectionContainer } from "./styles";
import { Control, Controller } from "react-hook-form";

interface Props {
  setData: (data: any) => void;
  title: string;
  control: Control;
  name: string;
}

const Placeholder = ({ ...rest }: PlaceholderProps) => {
  return <components.Placeholder {...rest} />;
};

const DropdownIndicator = ({ ...rest }: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...rest}>
      <IoCaretDownSharp size={12} color="#707070" />
    </components.DropdownIndicator>
  );
};

export function SelectSection({ setData, title, control, name }: Props) {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      minWidth: "160px",
      maxWidth: "270px",
    }),
    control: () => ({
      border: "0",
      outline: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "11px",
      color: "#707070",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "11px",
      color: "#707070",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "11px",
      color: "#707070",
    }),
  };
  function handleChangeData(newValue: any) {
    setData(newValue.value);
  }

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>

      <SelectSectionContainer>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              closeMenuOnSelect={false}
              components={{
                Placeholder,
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              value={value}
              placeholder={"指定なし"}
              options={yearData}
              styles={customStyles}
              onChange={onChange}
            />
          )}
        />
      </SelectSectionContainer>
    </Section>
  );
}

const yearData = [
  { label: "指定なし", value: "" },
  { label: "1年目", value: 1 },
  { label: "2年目", value: 2 },
  { label: "3年目", value: 3 },
  { label: "4年目", value: 4 },
  { label: "5年目", value: 5 },
  { label: "6年目", value: 6 },
  { label: "7年目", value: 7 },
  { label: "8年目", value: 8 },
  { label: "9年目", value: 9 },
  { label: "10年目", value: 10 },
  { label: "11年目", value: 11 },
  { label: "12年目", value: 12 },
  { label: "13年目", value: 13 },
  { label: "14年目", value: 14 },
  { label: "15年目", value: 15 },
  { label: "16年目", value: 16 },
  { label: "17年目", value: 17 },
  { label: "18年目", value: 18 },
  { label: "19年目", value: 19 },
  { label: "20年目", value: 20 },
];
