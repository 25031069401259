import styled from "styled-components";
import { colors } from "../../../../../../../styles/theme";

export const Container = styled.div`
  width: 100%;
  height: 35px;

  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  background: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  svg {
    color: ${colors.text_detail};
  }

  padding: 0 8px;
`;

export const StyledInput = styled.input`
  width: 100%;
  display: flex;
  flex: 1;
  border: none;
  outline: none;

  color: ${colors.text_detail};

  font-size: 11px;

  text-align: center;
  padding: 0 8px;

  margin-right: 16px;
`;
