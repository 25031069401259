import Geocode from "react-geocode";

const API_KEY = "AIzaSyBGJS_qJg5qTFMayFYNR1dL-nA07-sNEBc";
Geocode.setApiKey(API_KEY);
Geocode.setLanguage("ja");

export async function geocode(address: string): Promise<any> {
  return await Geocode.fromAddress(address);
}

interface LatLng {
  latitude: string;
  longitude: string;
}

export async function geocodeToAddress({ latitude, longitude }: LatLng) {
  return await Geocode.fromLatLng(latitude, longitude);
}
