import { createGlobalStyle } from "styled-components";
import { colors } from "./theme";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    scroll-behavior: smooth;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%; //15px;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%; //14px;
    }
  }

  body {
    overflow: hidden;

    background: ${colors.background_secondary};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: "Noto sans JP";
    font-size: 16px;
  }

  /* h1,h2,h3,h4,h5,h6, strong{
    font-weight: 500;
  } */

  button {
    cursor: pointer;
  }
`;
