import { SelectDate } from "../SelectDate";

import {
  Section,
  SectionTitleWrap,
  SectionTitle,
  ClearButton,
  ClearButtonText,
} from "./styles";

interface Props {
  title: string;
  setDate: (date: Date | null) => void;
  date: Date | null;
}

export function SelectDaySection({ title, setDate, date }: Props) {
  function handleResetValue() {
    setDate(null);
  }

  return (
    <Section>
      <SectionTitleWrap>
        <SectionTitle>{title}</SectionTitle>
        {!!date && (
          <ClearButton onClick={handleResetValue}>
            <ClearButtonText>×</ClearButtonText>
          </ClearButton>
        )}
      </SectionTitleWrap>

      <SelectDate date={date} setDate={setDate} />
    </Section>
  );
}
