import styled from "styled-components";
import { colors } from "../../../../styles/theme";

export const Container = styled.div`
  display: flex;
  flex: 1;
  z-index: 1;
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 15px;
  bottom: 25px;
`;

export const NavButton = styled.button`
  width: 50px;
  height: 50px;

  box-shadow: 0 3px 5px ${colors.shadow_400};
  background-color: ${colors.main};

  border-radius: 25px;

  margin-top: 10px;

  outline: none;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${colors.background_primary};
  }
`;

export const Button = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 25px;

  outline: none;
  border: none;

  background-color: ${colors.background_primary};
  box-shadow: 0 3px 6px ${colors.shadow_400};

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const PinImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const NewMarkerPin = styled.img`
  width: 40px;
  height: 40px;
`;
