import { HTMLAttributes } from "react";
import {
  MdClose,
  MdOutlineRestore,
  MdSystemUpdateAlt,
  MdDirections,
  MdChat,
  MdContacts,
} from "react-icons/md";
import { RiAccountPinBoxFill } from "react-icons/ri";

import { Container, ButtonWrapper, Title } from "./styles";

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  href: string;
  title: string;
  icons:
    | "close"
    | "systemUpdate"
    | "directions"
    | "restore"
    | "chat"
    | "contact"
    | "account";
  isSelected?: boolean;
  isClose?: boolean;
}

export function MenuLink({
  title,
  href,
  icons,
  isSelected = false,
  isClose = false,
  ...rest
}: Props) {
  return (
    <Container isClose={isClose}>
      <ButtonWrapper isSelected={isSelected} {...rest} href={href}>
        {icons === "close" && <MdClose size={18} />}
        {icons === "systemUpdate" && <MdSystemUpdateAlt size={18} />}
        {icons === "directions" && <MdDirections size={18} />}
        {icons === "restore" && <MdOutlineRestore size={18} />}
        {icons === "chat" && <MdChat size={18} />}
        {icons === "contact" && <MdContacts size={18} />}
        {icons === "account" && <RiAccountPinBoxFill size={18} />}
      </ButtonWrapper>
      <Title>{title}</Title>
    </Container>
  );
}
