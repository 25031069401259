import {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";
import { useForm } from "react-hook-form";

import newPinImage from "../../asset/pin/pin_5.svg";

import { geocodeToAddress } from "../../../../utils/geocode";

// import { SelectButton } from "../../components/Form/SelectButton";
// import { Ghost } from "../../components/Form/SelectButton/ghost";
// import { MenuButton } from "../../components/Buttons/MenuButton";
// import { CreatePinButton } from "../../components/Buttons/CreatePinButton";
// import { PinIcon } from "../../components/PinIcon";
// import { SelectSection } from "./components/SelectSection";
// import { SelectDaySection } from "./components/SelectDaySection";

// import { OneDaySelect } from "../OneDaySelect";

import { encodedParams } from "../../../../utils/encodedParams";
// import { uploadImage } from "../../../../utils/uploadImage";

import api from "../../../../services/api";

import { RegistredPinInfoDTO } from "../../../../dtos/RegistredPinInfoDTO";
import { ClientTypeDTO } from "../../../../dtos/ClientTypeDTO";

import {
  Container,
  ImageContainer,
  HouseImageInput,
  HouseImageLabel,
  HouseImage,
  ModalContent,
  Header,
  Content,
  ContentHeader,
  Pin,
  PinTitle,
  SectionContent,
  OpenAllButton,
  OpenAllContent,
  Section,
  SelectSectionContainer,
  MessageContainer,
  MessageHead,
  // MessageTitle,
  MessageSelectButton,
  MessageContent,
  PinDescriptionContainer,
  PinDescriptionTitle,
  PinDescription,
  PinImage,
} from "./styles";
import { PinIcon } from "../../../../components/PinIcon";
import { MenuButton } from "./components/MenuButton";
import { MenuLink } from "./components/MenuLink";
import { SelectStatusButton } from "../../../../components/SelectStatusButton";
import { Ghost } from "../../../../components/SelectStatusButton/ghost";
import { SelectSection } from "./components/SelectSection";
import { SelectDaySection } from "./components/SelectDaySection";
import { StyledSelect } from "./components/StyledSelect";
import { MdAdd } from "react-icons/md";
import { OptionDTO } from "../../../../dtos/OptionDTO";
import { ProductCategoryDTO } from "../../../../dtos/ProductCategoryDTO";
import { CreatePinButton } from "./components/CreatePinButton";

interface Props {
  closeModal: () => void;
  // setData: () => void;
  openAll: boolean;
  setOpenAll: () => void;
  latitude?: number;
  longitude?: number;
  pin_id: string;
}

interface RegistrePinForm {
  fid: number;
  ukey: string;
  lat?: number;
  lng?: number;
  pno?: string;
  mpid?: number;
  csid?: number;
  vtdt: string;
  vttm: string;
  appdt?: string;
  oppdt?: string;
  lbnm?: string;
  addr?: string;
  prd?: Array<{
    pid: number;
    insyy: string;
  }>;
}

export function CreateNewPin({
  closeModal,
  openAll,
  setOpenAll,
  latitude,
  longitude,
  pin_id,
}: Props) {
  const { control, handleSubmit, setValue, getValues, resetField } = useForm();

  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState<OptionDTO[]>([] as OptionDTO[]); // 定型文オプション
  const [selectTemplate, setSelectTemplate] = useState(""); // 選択中の定型文
  const [message, setMessage] = useState(""); //メッセージ欄

  const [clientPin, setClientPin] = useState<RegistredPinInfoDTO>();

  const [clientType, setClientType] = useState<ClientTypeDTO[]>();
  const [selectClientType, setSelectClientType] = useState<string>(""); // 選択中の顧客感度
  const [houseImage, setHouseImage] = useState<string | null>(null); // 家写真

  const [productCategory, setProductCategory] = useState<ProductCategoryDTO[]>(
    [] as ProductCategoryDTO[]
  );

  const [taiyokoUnder, setTaiyokoUnder] = useState(0);
  const [ecocuteUnder, setEcocuteUnder] = useState(0);

  const [apoDay, setApoDay] = useState<Date | null>(null);
  const [meetingDay, setMeetingDay] = useState<Date | null>(null);

  // ピン情報呼び出し
  async function loadPin() {
    try {
      const data = {
        ukey: "trial",
        fid: 7,
        mapcsid: pin_id,
      };

      const params = encodedParams(data);
      const response = await api.post("/", params);

      setHouseImage(response.data.data.photo_house);
      setSelectClientType(response.data.data.pno);

      if (response.data.data.appdt) {
        setApoDay(new Date(response.data.data.appdt));
      }

      if (response.data.data.oppdt) {
        setMeetingDay(new Date(response.data.data.oppdt));
      }

      setClientPin(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    console.log("newPin", pin_id);
    if (pin_id) {
      loadPin();
    }
  }, [pin_id]);

  // 定型文・顧客感度・商品カテゴリ呼び出し
  useEffect(() => {
    (async () => {
      try {
        const data = {
          ukey: "trial",
          fid: 9,
        };

        const params = encodedParams(data);
        const response = await api.post("/", params);

        const clientTypeData = {
          ukey: "trial",
          fid: 11,
        };

        const clientTypeParams = encodedParams(clientTypeData);
        const clientTypeResponse = await api.post("/", clientTypeParams);

        const parseTemplate: OptionDTO[] = response.data.data.fixmemo.map(
          (d: string) => {
            return {
              value: d,
              label: d,
            };
          }
        );

        const protuctCategoryData = {
          ukey: "trial",
          fid: 10,
        };

        const protuctCategoryParams = encodedParams(protuctCategoryData);
        const productCategoryResponse = await api.post(
          "/",
          protuctCategoryParams
        );

        setProductCategory(productCategoryResponse.data.data.prdlst); // 商品データの保存
        setClientType(clientTypeResponse.data.data.pinlst); // 顧客感度の保存
        setTemplate(parseTemplate); // 定型文の保存
        setSelectTemplate(String(parseTemplate[0].value)); // 定型文１つ目を選択初期に設定
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // 写真アップロード
  const handleUpdateHouseImage = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const file = e.target.files;
        if (!file) {
          throw new Error("ファイルを正しく選択してください");
        }

        const data = new FormData();

        if (!latitude && !longitude) {
          throw new Error("マップが選択されていません");
        }

        data.append("photo_house", file[0]);
        data.append("fid", "13");
        data.append("ukey", "trial");
        data.append("lat", String(latitude));
        data.append("lng", String(longitude));
        data.append("addr", "じゅうしょ");

        const response = await api.post("/", data);

        setHouseImage(response.data.data.photo_house);

        return;
      } catch (error) {
        console.log(error);
      }
    },
    [latitude, longitude]
  );

  // テンプレートデータをメッセージ内に挿入
  function handleAddMessage() {
    setMessage((oldState) => oldState + selectTemplate);
  }

  // ルート検索、グーグルマップ開く
  function handleOpenGoogleMap(latitude?: number, longitude?: number) {
    return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`;
  }

  // function handleNavigate(screen: string) {
  //   if (clientPin) {
  //     console.log("create new pin, clientpin ari", clientPin);
  //     return navigation.navigate(screen, {
  //       mpid: String(clientPin.mpid),
  //       csid: String(clientPin.csid),
  //       lat: String(clientPin.lat),
  //       lng: String(clientPin.lng),
  //     });
  //   }
  //   return navigation.navigate(screen, {
  //     lat: String(latitude),
  //     lng: String(longitude),
  //   });
  // }

  // ピン登録
  async function handleRegistrePin() {
    try {
      let appdt;
      if (apoDay) {
        console.log(apoDay);
        appdt = format(apoDay, "yyyy-MM-dd");
      }

      let oppdt;
      if (meetingDay) {
        oppdt = format(meetingDay, "yyyy-MM-dd");
      }

      const vtdt = format(new Date(), "yyyy-MM-dd");
      const vttm = format(new Date(), "HH:mm");

      const prd: Array<{
        pid: number;
        insyy: string;
      }> = [] as Array<{
        pid: number;
        insyy: string;
      }>;

      productCategory.forEach((d) => {
        const item: OptionDTO = getValues(String(d.pid));

        if (item) {
          prd.push({
            pid: d.pid,
            insyy: String(item?.value),
          });
        }
      });

      // 新規登録の場合
      if (pin_id === "newpin") {
        if (!latitude || !longitude) {
          alert("もう一度マップを選択してください。");
          throw new Error("latitude longitude is undefined");
        }

        const geocodeResponse = await geocodeToAddress({
          latitude: String(latitude),
          longitude: String(longitude),
        });

        const parseAddress = geocodeResponse.results.map(
          (d: { formatted_address: any }) => d.formatted_address
        );

        let data: RegistrePinForm = {
          fid: 8,
          ukey: "trial",
          lat: latitude,
          lng: longitude,
          vtdt,
          vttm,
          lbnm: "新規登録するピン",
          addr: parseAddress[0],
        };
        if (selectClientType) {
          data = {
            ...data,
            pno: selectClientType,
          };
        }
        if (appdt) {
          data = {
            ...data,
            appdt,
          };
        }

        if (oppdt) {
          data = {
            ...data,
            oppdt,
          };
        }

        const params = encodedParams(data);
        if (prd.length > 0) {
          params.append("prd", JSON.stringify(prd));
        }

        await api.post("/", params);

        return closeModal();
      }

      // 登録済みの場合
      if (clientPin) {
        let data: RegistrePinForm = {
          fid: 8,
          ukey: "trial",
          vtdt,
          vttm,
          lbnm: clientPin?.lbnm,
          mpid: clientPin?.mpid,
          csid: clientPin?.csid,
        };

        if (selectClientType) {
          data = {
            ...data,
            pno: selectClientType,
          };
        }
        if (appdt) {
          data = {
            ...data,
            appdt,
          };
        }

        if (oppdt) {
          data = {
            ...data,
            oppdt,
          };
        }

        const params = encodedParams(data);
        if (prd.length > 0) {
          params.append("prd", JSON.stringify(prd));
        }
        await api.post("/", params);

        return closeModal();
      }

      if (!clientPin) {
        alert("もう一度ピンを選択してください。");
        throw new Error("client-pin is undefined");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 訪問登録
  async function handleRegistreTodayPin() {
    try {
      if (!latitude || !longitude) {
        alert("もう一度マップを選択してください。");
        throw new Error("latitude longitude is undefined");
      }

      // 新規登録の場合
      if (pin_id === "newpin") {
        const geocodeResponse = await geocodeToAddress({
          latitude: String(latitude),
          longitude: String(longitude),
        });

        const parseAddress = geocodeResponse.results.map(
          (d: { formatted_address: any }) => d.formatted_address
        );

        const data = {
          fid: 12,
          ukey: "trial",
          lat: latitude,
          lng: longitude,
          addr: parseAddress[0],
        };

        const params = encodedParams(data);
        await api.post("/", params);

        return closeModal();
      }

      // 登録済みの場合
      const data = {
        fid: 12,
        ukey: "trial",
        mpid: clientPin?.mpid,
        csid: clientPin?.csid,
      };

      const params = encodedParams(data);
      await api.post("/", params);

      return closeModal();
    } catch (error) {
      console.log(error);
    }
  }

  // ピン写真取得
  const pinimage = useMemo(() => {
    const image = clientType?.find((pin) => clientPin?.pno === pin.pno)?.pim;
    if (!image) {
      return "";
    }

    return image;
  }, [clientPin?.pno, clientType]);

  return (
    <Container openAll={openAll}>
      {!openAll && (
        <ImageContainer>
          <HouseImageLabel htmlFor="house">
            <HouseImageInput
              type="file"
              id="house"
              onChange={handleUpdateHouseImage}
            />
          </HouseImageLabel>
          {houseImage && <HouseImage src={houseImage} />}
        </ImageContainer>
      )}

      <ModalContent>
        <Header>
          <MenuLink
            href={handleOpenGoogleMap(latitude, longitude)}
            title="ルート"
            icons="directions"
          />
          <MenuLink href="VisitHistory" title="訪問履歴" icons="restore" />
          <MenuLink href="ClientDetail" title="顧客情報" icons="account" />
          <MenuButton
            onClick={handleRegistreTodayPin}
            title="訪問登録"
            icons="systemUpdate"
          />
          <MenuButton
            onClick={closeModal}
            title="閉じる"
            icons="close"
            isClose={true}
          />
        </Header>

        {loading ? (
          <p>load...</p>
        ) : (
          <Content openAll={openAll}>
            <ContentHeader>
              <Pin>
                {pin_id === "newpin" ? (
                  <PinIcon pin={newPinImage} />
                ) : (
                  <PinImage src={pinimage} alt="pin" />
                )}

                {pin_id === "newpin" ? (
                  <PinTitle>新規登録するピン</PinTitle>
                ) : (
                  <PinDescriptionContainer>
                    <PinDescriptionTitle>{clientPin?.lbnm}</PinDescriptionTitle>
                    <PinDescription>住所:{clientPin?.addr}</PinDescription>
                    <PinDescription>訪問日:{clientPin?.vidt}</PinDescription>
                  </PinDescriptionContainer>
                )}
              </Pin>

              <CreatePinButton onClick={handleRegistrePin} />
            </ContentHeader>

            <SectionContent>
              {!clientType ? (
                <p>Load...</p>
              ) : (
                clientType.map((c) => (
                  <SelectStatusButton
                    key={c.pnm}
                    title={c.pnm}
                    pinImg={c.pim}
                    onClick={() => setSelectClientType(c.pno)}
                    isActive={selectClientType === c.pno}
                  />
                ))
              )}
              {!!clientType && clientType.length % 2 !== 0 && <Ghost />}
            </SectionContent>

            <OpenAllContent>
              <Section>
                {productCategory.length > 0 &&
                  productCategory.map((d) => (
                    <SelectSectionContainer>
                      <SelectSection
                        title={d.pnm}
                        setData={setTaiyokoUnder}
                        name={String(d.pid)}
                        control={control}
                      />
                    </SelectSectionContainer>
                  ))}

                <SelectSectionContainer>
                  <SelectDaySection
                    title="アポイント設定日"
                    setDate={setApoDay}
                    date={apoDay}
                  />
                </SelectSectionContainer>

                <SelectSectionContainer>
                  <SelectDaySection
                    title="商談設定日"
                    setDate={setMeetingDay}
                    date={meetingDay}
                  />
                </SelectSectionContainer>
              </Section>

              <MessageContainer>
                <MessageHead>
                  <StyledSelect
                    setData={(data) => setSelectTemplate(data)}
                    option={template}
                    defaultValue={template[0]}
                  />

                  <MessageSelectButton onClick={handleAddMessage}>
                    <MdAdd size={16} />
                  </MessageSelectButton>
                </MessageHead>
                <MessageContent
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </MessageContainer>
            </OpenAllContent>
          </Content>
        )}
      </ModalContent>

      <OpenAllButton onClick={setOpenAll}>
        {openAll ? (
          <IoCaretDownSharp size={22} />
        ) : (
          <IoCaretUpSharp size={22} />
        )}
      </OpenAllButton>
    </Container>
  );
}
