export const colors = {
  background_primary: "#FFFFFF",
  background_secondary: "#F7F8F8",
  background_third: "#E5E6EC",
  background_four: "#EDEDED",

  text: "#333333",
  text_detail: "#707070",
  message: "#BEBEBE",
  title: "#47474d",

  shadow_100: "#0000000D",
  shadow_200: "#00000017",
  shadow_400: "#00000029",
  shadow_500: "#3333334D",

  border_0: "#FFFFFF",
  border_100: "#EDEEF2",
  border_200: "#DEE2E6",
  border_300: "#575F6B",
  border_350: "#586C8A",

  main: "#48C6EF",
  main_light: "#C2E4EF",
  main_100: "#6F86D6",

  pin_1: "#F369A7",
  pin_2: "#FCDB13",
  pin_3: "#A5D2DE",
  pin_4: "#A5CB1A",
  pin_5: "#707070",
};
