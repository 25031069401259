import { forwardRef, useRef } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import Input from "./Input";

registerLocale("ja", ja);

interface Props {
  date: Date | undefined | null;
  setDate: (date: Date | null) => void;
}

const CustomInput = forwardRef((props: any, ref) => {
  return <Input {...props} ref={ref} />;
});

export function SelectDate({ setDate, date }: Props) {
  const inputRef = useRef(null);

  return (
    <DatePicker
      locale="ja"
      dateFormat="yyyy.MM.dd"
      selected={date}
      customInput={<CustomInput inputRef={inputRef} />}
      onChange={(date: Date | null) => setDate(date)}
      showMonthDropdown
      showYearDropdown
      placeholderText="指定なし"
    />
  );
}
