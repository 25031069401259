import styled from "styled-components";
import { colors } from "../../styles/theme";

interface ButtonProps {
  isActive: boolean;
}

export const Container = styled.div<ButtonProps>`
  width: 32%;
  border: ${({ isActive }) => (isActive ? colors.main : colors.text_detail)} 1px
    solid;
  border-radius: 5px;

  background-color: ${colors.background_primary};

  margin-bottom: 8px;

  overflow: hidden;
`;

export const Button = styled.button`
  width: 100%;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 42px;

  background-color: ${colors.background_primary};
  outline: none;
  border: none;
`;

export const PinImage = styled.img`
  width: 18px;
  height: 18px;

  object-fit: contain;
`;

export const Title = styled.p<ButtonProps>`
  font-size: 11px;
  line-height: 15px;

  padding-left: 6px;

  color: ${({ isActive }) => (isActive ? colors.main : colors.text_detail)};
`;

export const GhostContainer = styled.div`
  width: 32%;
  height: 42px;

  background-color: transparent;
  margin-bottom: 8px;
`;
