import Select, { components, DropdownIndicatorProps } from "react-select";
import { FaSort } from "react-icons/fa";
import { OptionDTO } from "../../../../../../dtos/OptionDTO";

interface Props {
  setData: (data: any) => void;
  option: OptionDTO[];
  defaultValue: unknown;
}

const DropdownIndicator = ({ ...rest }: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...rest}>
      <FaSort size={12} color="#707070" />
    </components.DropdownIndicator>
  );
};

export function StyledSelect({ setData, option, defaultValue }: Props) {
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      marginRight: "5px",
      height: "21px",
    }),
    control: () => ({
      border: "1px solid #DEE2E6",
      outline: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "11px",
      color: "#707070",
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: "11px",
      color: "#707070",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "11px",
      color: "#707070",
    }),
  };

  function handleChangeData(newValue: any) {
    console.log(newValue.value);
    setData(newValue.value);
  }

  return (
    <Select
      closeMenuOnSelect={false}
      defaultValue={defaultValue}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      options={option}
      styles={customStyles}
      onChange={(newValue) => handleChangeData(newValue)}
    />
  );
}
