import styled from "styled-components";
import { colors } from "../../../../../../styles/theme";

export const Section = styled.div`
  position: relative;

  width: 100%;
`;

export const SectionTitle = styled.p`
  font-size: 12px;

  color: ${colors.text};
  border-left: 2px solid ${colors.text};

  padding-left: 8px;
  margin-bottom: 18px;
`;

export const SelectSectionContainer = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  display: flex;
  background: ${colors.background_primary};
  border: 1px solid ${colors.border_200};

  border-radius: 4px;

  color: ${colors.text_detail};

  font-size: 0.9375rem;

  text-align: center;
`;
