import { Container, PinImage } from "./styles";

interface Props {
  pin: string;
}

export function PinIcon({ pin }: Props) {
  return (
    <Container>
      <PinImage src={pin} alt="pin" />
    </Container>
  );
}
