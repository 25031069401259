import { HTMLAttributes } from "react";

import { Container, Button, Title, PinImage } from "./styles";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  isActive: boolean;
  ghost?: boolean;
  pinImg?: string;
}

export function SelectStatusButton({
  isActive,
  title,
  pinImg,
  ghost = false,
  ...rest
}: Props) {
  return (
    <Container isActive={isActive}>
      <Button {...rest}>
        {!!pinImg && <PinImage src={pinImg} />}

        <Title isActive={isActive}>{title}</Title>
      </Button>
    </Container>
  );
}
