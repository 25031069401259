import { useEffect, useState } from "react";

import OneSvg from "../../asset/ranking/one.svg";

import { encodedParams } from "../../../../utils/encodedParams";
import api from "../../../../services/api";

import { RankingDTO } from "../../../../dtos/RankingDTO";
import {
  RankingContainer,
  RankingContentWrapper,
  Ranking,
  RankingContent,
  OneIcon,
} from "./styles";

export function RankingHeader() {
  const [ranking, setRanking] = useState<RankingDTO>({} as RankingDTO);
  const [isStop, setIsStop] = useState(false);

  async function loadRanking() {
    try {
      const data = {
        ukey: "trial",
        fid: 3,
      };

      const params = encodedParams(data);
      const response = await api.post("/", params);

      setRanking(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadRanking();
  }, []);

  return (
    <RankingContainer>
      <RankingContentWrapper isStop={isStop} onClick={() => setIsStop(!isStop)}>
        {ranking &&
          ranking.jisseki &&
          ranking.jisseki.map((data) => {
            return (
              <Ranking key={data.naiyou}>
                <h3>{data.naiyou}</h3>
                <RankingContent>
                  <p>
                    {data.per}%　本日{data.today}　合計{data.total}
                  </p>
                </RankingContent>
              </Ranking>
            );
          })}

        {ranking &&
          ranking.monthly &&
          ranking.monthly.map((data) => {
            const Ranks = data.rank.map((r) => {
              if (r.juni === 1) {
                return (
                  <RankingContent key={r.unm}>
                    <OneIcon src={OneSvg} alt="1位" />
                    <p>
                      {r.unm}　{r.cnt}件
                    </p>
                  </RankingContent>
                );
              }
              return (
                <RankingContent>
                  <strong>{r.juni}位</strong>
                  <p>
                    {r.unm}　{r.cnt}件
                  </p>
                </RankingContent>
              );
            });

            return (
              <Ranking key={data.naiyou}>
                <h3>{data.naiyou}</h3>
                {Ranks.length === 0 ? <p>本日活動中</p> : Ranks}
              </Ranking>
            );
          })}

        {ranking &&
          ranking.today &&
          ranking.today.map((data) => {
            const Ranks = data.rank.map((r) => {
              if (r.juni === 1) {
                return (
                  <RankingContent key={r.unm}>
                    <OneIcon src={OneSvg} alt="1位" />

                    <p>
                      {r.unm}　{r.cnt}件
                    </p>
                  </RankingContent>
                );
              }
              return (
                <RankingContent>
                  <strong>{r.juni}位</strong>
                  <p>
                    {r.unm}　{r.cnt}件
                  </p>
                </RankingContent>
              );
            });

            return (
              <Ranking key={data.naiyou}>
                <h3>{data.naiyou}</h3>
                {Ranks.length === 0 ? <p>本日活動中</p> : Ranks}
              </Ranking>
            );
          })}
      </RankingContentWrapper>
    </RankingContainer>
  );
}
