import styled from "styled-components";
import { colors } from "../../styles/theme";

export const Container = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 10px solid ${colors.background_primary};

  button {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    background: ${colors.background_primary};

    padding-top: 7px;
    margin-bottom: auto;

    border: none;
    outline: none;
    p {
      padding-bottom: 30px;
      color: ${colors.text_detail};
      font-size: 9px;
    }
    svg {
      color: ${colors.text_detail};
    }
  }
`;

export const Read = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 18px;
  top: 2px;

  color: ${colors.background_primary};
  background: red;

  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;

  font-size: 12px;
  padding: 1px;
`;
