import styled from "styled-components";
import { colors } from "../../../../../../styles/theme";

export const Section = styled.div`
  position: relative;

  width: 100%;
`;

export const SectionTitleWrap = styled.div`
  border-left: 2px solid ${colors.text};

  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;
`;

export const SectionTitle = styled.div`
  font-size: 12px;
  line-height: 17px;

  color: ${colors.text};

  margin-left: 8px;
`;

export const ClearButton = styled.button`
  background-color: #c3c3c3;
  padding: 0 8px;
  border-radius: 10px;

  border: 0;
  outline: none;
`;

export const ClearButtonText = styled.p`
  font-size: 9px;

  color: ${colors.background_primary};
`;
