import { HTMLAttributes } from "react";

import { Button, ButtonText } from "./styles";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
}

export function CreatePinButton({ onClick, ...rest }: Props) {
  return (
    <Button {...rest} onClick={onClick}>
      <ButtonText>ピン登録</ButtonText>
    </Button>
  );
}
