import styled from "styled-components";
import { colors } from "../../../../styles/theme";

interface OpenProps {
  openAll: boolean;
}

export const Container = styled.div<OpenProps>`
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  justify-content: flex-end;

  height: 100%;

  z-index: 5;

  position: relative;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: -107px;
  left: 0;

  margin: 10px;
  width: 128px;
  height: 92px;

  border: 1px solid ${colors.border_100};
  border-radius: 4px;

  box-shadow: 0 3px 6px ${colors.shadow_500};
  background-color: ${colors.background_third};
`;

export const HouseImage = styled.img`
  width: 128px;
  height: 92px;

  object-fit: contain;
`;

export const HouseImageInput = styled.input`
  display: none;
`;

export const HouseImageLabel = styled.label`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 4px;

  width: 128px;
  height: 92px;

  object-fit: contain;
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  border-radius: 8px;
  background-color: ${colors.background_primary};

  box-shadow: 0 0px 6px ${colors.shadow_200};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: flex-end;

  padding: 16px;

  border-bottom: 1px solid ${colors.border_300};
`;

export const Content = styled.div<OpenProps>`
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding-bottom: 90px;
`;

export const ContentHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Pin = styled.div`
  display: flex;
  align-items: center;
`;

export const PinTitle = styled.p`
  color: ${colors.text};

  font-size: 12px;
  margin-left: 10px;
`;

export const PinDescriptionContainer = styled.div`
  margin-left: 10px;
`;

export const PinDescriptionTitle = styled.p`
  color: ${colors.text};

  font-size: 12px;
`;

export const PinDescription = styled.p`
  color: ${colors.text_detail};

  font-size: 10px;
`;

export const SectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 18px;
`;

export const OpenAllButton = styled.button`
  position: absolute;

  bottom: 15px;
  right: 15px;

  width: 42px;
  height: 42px;

  border-radius: 22px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${colors.border_100};

  background-color: ${colors.background_primary};

  svg {
    color: ${colors.text_detail};
  }
`;

export const OpenAllContent = styled.div`
  width: 100%;
  /* padding-bottom: 80px; */
`;

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  /* margin: 18px 0; */
`;

export const SelectSectionContainer = styled.div`
  width: 48%;
  margin-top: 27px;
`;

export const MessageContainer = styled.div`
  margin-top: 15px;
`;

export const MessageHead = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: flex-start;
`;

export const MessageSelectButton = styled.button`
  width: 33px;
  height: 28px;

  outline: none;
  border: none;

  background-color: ${colors.background_third};

  justify-content: center;
  align-items: center;

  svg {
    color: ${colors.text_detail};
  }
`;

export const MessageContent = styled.textarea`
  width: 100%;
  min-height: 100px;
  margin-top: 5px;
  padding: 16px;

  border: 1px solid ${colors.background_third};

  font-size: 11px;
  color: ${colors.text_detail};
`;

export const PinImage = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 20px;

  justify-content: center;
  align-items: center;

  border-width: 1px;
  border-color: ${colors.border_100};

  object-fit: contain;
`;
