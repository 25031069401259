import styled from "styled-components";
import { colors } from "../../styles/theme";

export const Container = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 50%;

  justify-content: center;
  align-items: center;

  border-width: 1px;
  border-color: ${colors.border_100};

  margin-right: 15px;
`;

export const PinImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
`;
