import styled from "styled-components";
import { colors } from "../../../../../../styles/theme";

export const Button = styled.button`
  padding: 10px;
  border-radius: 5px;

  justify-content: center;
  align-items: center;

  box-shadow: 0 2px 1px ${colors.shadow_500};
  background-color: ${colors.main};

  border: none;
  outline: none;
`;

export const ButtonText = styled.p`
  color: ${colors.background_primary};

  font-size: 13px;
`;
